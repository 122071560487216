<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <div class="mx-4">
        <b-row>
          <!-- SKU -->
          <b-form-group class="col-md-6">
            <label for="">SKU</label
            ><b-button
              variant="link outline-secondary ml-auto"
              @click="hability = !hability"
              >{{
                hability ? "Habilitar edición" : "Deshabilitar edición"
              }}</b-button
            >
            <ValidationProvider name="SKU" rules="" v-slot="{ errors }">
              <b-form-input
                :disabled="hability"
                v-model="sku"
                type="text"
                placeholder="código único de producto"
                :class="
                  errors.length > 0
                    ? ' is-invalid'
                    : answer === 1
                    ? 'is-invalid'
                    : answer === 2
                    ? 'is-valid'
                    : ''
                "
              >
              </b-form-input>
              <small
                :class="
                  answer === 0
                    ? 'text-dark'
                    : answer === 1
                    ? 'text-danger'
                    : answer === 2
                    ? 'text-success'
                    : 'text-warning'
                "
                >{{
                  answer === null
                    ? ""
                    : answer === 0
                    ? "Generando SKU ..."
                    : answer === 1
                    ? "SKU existente"
                    : answer === 2
                    ? "SKU Válido"
                    : "Verificando SKU"
                }}</small
              >

              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <!-- NOMBRE DE PRODUCTO -->
          <b-form-group
            class="col-md-6"
            label="Nombre de Producto"
            label-for="name"
          >
            <ValidationProvider
              name="Nombre"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-input
                v-model="name"
                type="text"
                placeholder="Nombre de producto"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-form-input>
              <div v-if="checkName !== 0">
                <small v-if="checkName === 1">Verificando nombre..</small>
                <small v-if="checkName === 2"
                  ><i class="ri-alert-line h6 text-warning"></i> Ya existen
                  productos registrados con este nombre.</small
                >
              </div>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <!-- Marca -->
          <b-form-group
            class="col-sm-6"
            label-for="Marca De Producto"
            label="Marca De Producto"
          >
            <v-select
              v-model="form.brand"
              placeholder="Seleccionar..."
              label="name"
              :options="getBrand"
              :selectable="
                (item) => (item.slug === 'sin-marca' ? item.disabled : item)
              "
            >
              <template #no-options="{}">
                Sin Resultados,
                <button
                  class="btn btn-link"
                  @click="$refs.createOrUpdate.showModal()"
                >
                  ¿Desea registrar una marca?
                </button>
              </template>
              <template v-slot:option="option">
                <slot :disabled="true"
                  ><b-avatar
                    variant="success"
                    class="mr-1"
                    :src="option.image"
                  ></b-avatar>
                  {{ option.name }}
                  <button
                    v-if="option.slug === 'sin-marca' ? true : false"
                    class="btn btn-link"
                    @click="$refs.createOrUpdate.showModal()"
                  >
                    ¿Desea registrar una marca?
                  </button></slot
                >
              </template>
            </v-select>
          </b-form-group>

          <!-- CATEGORIA -->
          <b-form-group
            class="col-sm-6"
            label-for="Categoría"
            label="Categoría"
          >
            <v-select
              v-model="category"
              placeholder="Seleccionar..."
              label="name"
              multiple
              :options="getCategory"
              id="selectcountry"
              :selectable="
                (item) => (item.name === 'Sin Categoría' ? item.disabled : item)
              "
            >
              <template #no-options="{}">
                Sin Resultados,
                <button class="btn btn-link" @click="$refs.modal.show()">
                  ¿Desea registrar una categoría?
                </button>
              </template>
              <template v-slot:option="option">
                <slot :disabled="true"
                  ><b-avatar
                    variant="success"
                    class="mr-1"
                    :src="option.image"
                  ></b-avatar>
                  {{ option.name }}
                  <button
                    v-if="option.name === 'Sin Categoría' ? true : false"
                    class="btn btn-link"
                    @click="$refs.modal.show()"
                  >
                    ¿Desea registrar una categoria?
                  </button></slot
                >
              </template>
            </v-select>
          </b-form-group>

          <!-- TIPO -->
          <div class="form-group col-4">
            <b-form-group label-for="Tipo" label="Tipo">
              <v-select
                label="text"
                v-model="form.type"
                placeholder="Seleccionar..."
                :options="ti"
              >
                <template #no-options="{}"> Sin Resultados... </template>
              </v-select>
            </b-form-group>
          </div>
          <!-- MODELO -->
          <b-form-group class="col-md-4" label="Modelo" label-for="Modelo">
            <ValidationProvider name="Modelo" rules="" v-slot="{ errors }">
              <b-form-input
                v-model="modelo"
                type="text"
                placeholder="Ingrese Modelo"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              ></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>

          <!-- COLOR -->
          <b-form-group class="col-md-4" label="Color" label-for="Color">
            <b-form-input
              v-model="color"
              type="text"
              placeholder="Ingresar un color"
            ></b-form-input>
          </b-form-group>

          <!-- PESO -->
          <div class="form-group col-6">
            <label>Peso</label>
            <div class="input-group">
              <b-form-input
                v-model="peso"
                type="number"
                placeholder="Peso"
              ></b-form-input>
              <b-form-select
                class="col-3"
                plain
                v-model="unidad_peso"
                placeholder="Seleccionar..."
                :options="['Kg', 'g']"
                id="comunas"
              >
              </b-form-select>
            </div>
          </div>

          <!-- MEDIDAS -->
          <div class="form-group col-6">
            <label>Medidas</label>
            <div class="input-group">
              <b-form-input
                v-model="alto"
                type="number"
                placeholder="Alto"
              ></b-form-input>
              <b-form-input
                v-model="ancho"
                type="number"
                placeholder="Ancho"
              ></b-form-input>
              <b-form-input
                v-model="largo"
                type="number"
                placeholder="Largo"
              ></b-form-input>
              <b-form-select
                plain
                v-model="escala"
                placeholder="Seleccionar..."
                :options="['m', 'cm', 'mm']"
                id="comunas"
              >
              </b-form-select>
            </div>
          </div>
          <!-- comentario -->
          <b-form-group
            class="col-md-12"
            label="Comentario"
            label-for="Comentario"
          >
            <b-form-textarea
              id="textarea"
              v-model="form.commentary"
              placeholder="Ingrese alguna información adicional relevante"
              rows="2"
              max-rows="4"
            ></b-form-textarea>
          </b-form-group>
        </b-row>
      </div>
      <hr />
      <div class="mx-4">
        <b-button variant="primary" type="submit">Guardar</b-button>
      </div>
    </form>
    <modals ref="createOrUpdate" />
    <modal ref="modal"></modal>
  </ValidationObserver>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapGetters, mapActions } from "vuex";
import modals from "@/components/core/modals/BrandsModals";
import modal from "@/components/core/modals/CategoryModals";
import _ from "lodash";
export default {
  props: {
    origin: { default: 0 },
    data: { 
      type: Object,
      default: () => null
    },
  },
  components: {
    modals,
    modal,
  },
  async created() {
    await this.listProducts({ search: null });
    this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
    this.debouncedGetName = _.debounce(this.getCheckName, 500);

    // CARGAR SELECT DE CATEGORIA
    await this.listCategory();

    // CARGAR SELECT DE MARCA
    await this.listBrand();
    if (this.data) {
      this.form = {
        id: this.data.id,
        sku: this.data.sku,
        name: this.data.name,
        brand: this.data.brand,
        commentary: this.data.commentary,
      };
      this.name = this.data.name
      this.category = this.data.categories;
      const re = this.ti.filter((item) => item.code === this.data.type);
      this.form.type = re[0];
      for (let i = 0; i < this.data.attributes.length; i++) {
        if (this.data.attributes[i].attr === "height") {
          this.ids.alto = this.data.attributes[i].id;
          this.escala = this.data.attributes[i].unit;
          this.alto = this.data.attributes[i].value;
        }
        if (this.data.attributes[i].attr === "width") {
          this.ids.ancho = this.data.attributes[i].id;
          this.escala = this.data.attributes[i].unit;
          this.ancho = this.data.attributes[i].value;
        }
        if (this.data.attributes[i].attr === "length") {
          this.ids.largo = this.data.attributes[i].id;
          this.escala = this.data.attributes[i].unit;
          this.largo = this.data.attributes[i].value;
        }
        if (this.data.attributes[i].attr === "weight") {
          this.ids.peso = this.data.attributes[i].id;
          this.unidad_peso = this.data.attributes[i].unit;
          this.peso = this.data.attributes[i].value;
        }
        if (this.data.attributes[i].attr === "color") {
          this.ids.color = this.data.attributes[i].id;
          this.color = this.data.attributes[i].value;
        }
        if (this.data.attributes[i].attr === "model") {
          this.ids.modelo = this.data.attributes[i].id;
          this.modelo = this.data.attributes[i].value;
        }
      }
      this.sku = this.data.sku;
    } else {
      this.sku = await this.skuProducts();
      this.form = {
        id: null,
        sku: "",
        name: "",
        brand: "",
        commentary: "",
        attributes: "",
      };
      const l = this.getBrand.filter((item) => item.slug === "sin-marca");
      this.form.brand = l[0];
      this.category = this.getCategory.filter(
        (item) => item.name === "Sin Categoría"
      );
    }
  },
  async mounted() {
    core.index();
  },
  watch: {
    sku: function (newQuestion, oldQuestion) {
      this.answer = 3;
      this.debouncedGetAnswer();
    },
    name() {
      this.checkName = 1;
      this.debouncedGetName();
    },
  },
  data() {
    return {
      hability: true,
      form: {
        id: null,
        sku: null,
        name: "",
        commentary: "",
        attributes: "",
        categories: [],
        brand: "",
        type: { code: 3, text: "Insumos" },
      },
      checkName: 0,
      sku: "",
      name: "",
      answer: 0,
      category: "",
      color: null,
      peso: null,
      modelo: null,
      alto: null,
      ancho: null,
      largo: null,
      escala: "cm",
      unidad_peso: "g",
      ids: {
        alto: null,
        ancho: null,
        largo: null,
        peso: null,
        modelo: null,
        color: null,
      },
      categorys: [],
      brands: [],
      ti: [
        { code: 1, text: "Bienes" },
        this.origin == 0 ? { code: 2, text: "Bienes Con Responsables" } : "",
        { code: 3, text: "Insumos" },
      ],
    };
  },
  methods: {
    ...mapActions({
      listProducts: "products/listProducts",
      createOrUpdate: "products/storeProducts",
      listCategory: "category/listCategory",
      listBrand: "brand/listBrand",
      skuProducts: "products/skuProducts",
      nameProduct: "products/nameProducts",
    }),
    async onSubmit() {
      const l = this.getBrand.filter((item) => item.slug === "sin-marca");
      const payload = {
        id: this.form.id,
        sku: this.sku,
        name: this.name,
        type: this.form.type.code,
        commentary: this.form.commentary,
        categories: [],
        brand: this.form.brand ? this.form.brand.id : l[0].id,
        attributes: [],
      };
      if (this.category.length > 0) {
        for (let i = 0; i < this.category.length; i++) {
          payload.categories.push(this.category[i].id);
        }
      } else {
        let category = this.getCategory.filter(
          (item) => item.name === "Sin Categoría"
        );
        payload.categories = [category[0].id];
      }
      if (this.alto) {
        payload.attributes.push({
          id: this.ids.alto ? this.ids.alto : null,
          attr: "height",
          unit: this.escala == null ? 0 : this.escala,
          value: this.alto == null ? 0 : this.alto,
        });
      }
      if (this.ancho) {
        payload.attributes.push({
          id: this.ids.ancho ? this.ids.ancho : null,
          attr: "width",
          unit: this.escala == null ? 0 : this.escala,
          value: this.ancho == null ? 0 : this.ancho,
        });
      }
      if (this.largo) {
        payload.attributes.push({
          id: this.ids.largo ? this.ids.largo : null,
          attr: "length",
          unit: this.escala == null ? 0 : this.escala,
          value: this.largo == null ? 0 : this.largo,
        });
      }
      if (this.peso) {
        payload.attributes.push({
          id: this.ids.peso ? this.ids.peso : null,
          attr: "weight",
          unit: this.unidad_peso,
          value: this.peso == null ? 0 : this.peso,
        });
      }
      if (this.color) {
        payload.attributes.push({
          id: this.ids.color ? this.ids.color : null,
          attr: "color",
          unit: null,
          value: this.color ? this.color : "Sin Color",
        });
      }
      if (this.modelo) {
        payload.attributes.push({
          id: this.ids.modelo ? this.ids.modelo : null,
          attr: "model",
          unit: null,
          value: this.modelo ? this.modelo : "Sin Modelo",
        });
      }
      try {
        const res = await this.createOrUpdate(payload);
        core.showSnackbar("success", res.message);
        if (this.origin == 0) {
          this.$router.replace("/products/list");
        } else {
          this.$emit("hide", res.data);
          core.showSnackbar("success", res.message);
        }
      } catch (error) {
        console.log(error);
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    async getAnswer() {
      this.answer = 3;
      let payload = { sku: this.sku, id: this.data ? this.data.id : null };
      let data = await this.skuProducts(payload);
      if (data && !this.data) {
        this.answer = 1;
      } else if (data && this.data) {
        this.answer = 2;
      } else {
        this.answer = 2;
      }
    },
    async getCheckName() {
      this.checkName = 1;
      let data = await this.nameProduct(this.name);
      if (data.exists) {
        this.checkName = 2;
      } else {
        this.checkName = 0;
      }
    },
  },
  computed: {
    ...mapGetters({
      getCategory: "category/getCategory",
      getBrand: "brand/getBrands",
    }),
  },
};
</script>